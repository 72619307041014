import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { TbTLogo } from "components/shared";
import { Icon } from "components/shared/Icon";
import { TbTCheck } from "components/shared/TbTCheck";
import { TbTLogoVariant } from "components/shared/TbTLogo";
import { Fragment } from "react";

type Props = {
  open: boolean;
  children: React.ReactNode;
  setOpen: (open: boolean) => void;
};

export const MobileSidebar = ({ open, setOpen, children }: Props) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 flex z-40 md:hidden"
      onClose={setOpen}
    >
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div
          className={clsx(
            "relative flex-1 flex flex-col max-w-xs w-full",
            "bg-slate-800 p-0 pb-4"
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute top-2 right-2">
              <button
                type="button"
                className="ml-1 flex items-center justify-center h-10 w-10"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <Icon
                  icon="openSidebar"
                  size={7}
                  color="text-white hover:text-blue-500"
                />
              </button>
            </div>
          </Transition.Child>
          <div className="flex items-center px-4 gap-[3px] h-14">
            <TbTCheck size={38} background="bg-white" />
            <TbTLogo
              width={100}
              variant={TbTLogoVariant.WHITE_NONE}
              className="mt-[8px]"
            />
          </div>
          {children}
        </div>
      </Transition.Child>
      <div className="flex-shrink-0 w-14" aria-hidden="true" />
    </Dialog>
  </Transition.Root>
);
