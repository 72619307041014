import { Icon } from "components/shared/Icon";
import { AvatarDropdown } from "./components/AvatarDropdown";
import { Breadcrumbs } from "./components/Breadcrumbs/Breadcrumbs";
import { NotificationDropdown } from "./components/NotificationDropdown/NotificationDropdown";
import { PendingAgreementContainer } from "./components/PendingAgreementContainer";
import { SpoofPanel } from "./components/SpoofPanel";

type Props = { setSidebarOpen?: (data: boolean) => void };

export const AuthHeader = ({ setSidebarOpen }: Props) => (
  <div className="flex justify-between sticky top-0 z-10 w-full h-16 bg-slate-800 shadow-lg">
    <div className="flex items-center justify-start">
      {setSidebarOpen && (
        <div className="flex items-center justify-start h-full w-auto bg-slate-900">
          <button
            type="button"
            className="px-[12px] border-r border-gray-500 text-gray-500 md:hidden mr-[5px]"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Icon icon="menu" size={8} color="text-gray-200 hover:text-white" />
          </button>
        </div>
      )}
      <Breadcrumbs />
    </div>
    <div className="flex w-fit justify-end items-center gap-2 px-5">
      <SpoofPanel />
      <NotificationDropdown />
      <AvatarDropdown />
      <PendingAgreementContainer />
    </div>
  </div>
);
