import { gql } from "@apollo/client";
import {
  AgreementDocumentPromptType,
  PromptStateBuilder_AgreementDocumentPromptFragment,
} from "@generated/graphql";
import { CHECKBOX_FALSE } from "./constants";
import { ResponseState } from "./types";

responseStateBuilder.fragments = {
  agreementDocumentPrompt: gql`
    fragment PromptStateBuilder_AgreementDocumentPrompt on AgreementDocumentPrompt {
      index
      type
      text
      required
    }
  `,
};

export function responseStateBuilder(
  prompts: PromptStateBuilder_AgreementDocumentPromptFragment[]
): ResponseState[] {
  return prompts.map((prompt) => ({
    index: prompt.index,
    value:
      prompt.type === AgreementDocumentPromptType.Checkbox
        ? CHECKBOX_FALSE
        : "",
    error: null,
  }));
}
