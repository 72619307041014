import clsx from "clsx";
import NextLink from "next/link";
import { HTMLAttributeAnchorTarget, ReactNode } from "react";

type Props = {
  href: string;
  children: ReactNode;
  className?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
};

export const LegacyLink = ({ href, children, className, target }: Props) => (
  <NextLink href={href} legacyBehavior passHref>
    <a
      className={clsx(
        "font-medium text-blue-500 hover:underline hover:underline-offset-2",
        className
      )}
      onClick={(e) => e?.stopPropagation()}
      target={target}
      rel={target === "_blank" ? "noreferrer" : undefined}
    >
      {children}
    </a>
  </NextLink>
);
