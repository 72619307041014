import { Button } from "components/shared";
import { AuthStatus } from "../types";

type StatusProps = { status: AuthStatus };

type Props = {
  Icon: (props: StatusProps) => JSX.Element;
  text: string;
  status: AuthStatus;
};

export const LoginButtonAndIcon = ({ status, Icon, text }: Props) => (
  <Button
    className="group relative flex items-center w-full h-10"
    type="submit"
  >
    <Icon status={status} />
    {status === "idle" && <span className="ml-2">{text}</span>}
  </Button>
);
