import { refreshPage } from "@utils/browser";
import { Routes } from "@utils/routes";
import { getSpoofUser } from "@utils/withFragments/spoof";
import { useAuth } from "contexts/AuthProvider";
import { useRouter } from "next/router";
import { AgreementModal } from "./components/AgreementModal";

export function PendingAgreementContainer() {
  const auth = useAuth();
  const router = useRouter();
  const spoofUser = getSpoofUser();

  const onClose = async () => {
    if (Routes.myProfile.accountRoleAccess.includes(auth.user?.accountRole))
      await router.push(Routes.myProfile.href());
    refreshPage();
  };

  return auth.user?.hasPendingAgreements && !spoofUser ? (
    <AgreementModal onClose={onClose} />
  ) : null;
}
