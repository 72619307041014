import { Routes } from "@utils/routes";
import { IconMap } from "components/shared/Icon";
import { AdminMode } from "types/global";
import { SidebarLink } from "../types";
import {
  getEngagementCurrentRoute,
  getOrganizationCurrentRoute,
} from "./utils";

export const getAdminLinks = (mode: AdminMode, currentPathname: string) => {
  switch (mode) {
    case AdminMode.Dashboard:
      return [];
    case AdminMode.Managing:
      return getAdminManageLinks(currentPathname);
    case AdminMode.Staffing:
      return getAdminStaffingLinks(currentPathname);
    case AdminMode.ControlPanel:
      return getAdminControlPanelLinks(currentPathname);
    default:
      return getAdminManageLinks(currentPathname);
  }
};

export const getCommonAdminSidebarLinks = (currentPathname: string) => [
  {
    name: "Organizations",
    route: Routes.organizations,
    icon: IconMap["organization"],
    current: getOrganizationCurrentRoute(currentPathname),
  },
  {
    name: "Engagements",
    route: Routes.engagements,
    icon: IconMap["engagement"],
    current: getEngagementCurrentRoute(currentPathname),
  },
];

export const getAdminManageLinks = (currentPathname: string) =>
  [
    ...getCommonAdminSidebarLinks(currentPathname),
    {
      name: "Cohorts",
      route: Routes.cohorts,
      icon: IconMap["cohort"],
      current: currentPathname?.startsWith(Routes.cohorts.path()),
    },
    {
      name: "Students",
      route: Routes.students,
      icon: IconMap["student"],
      current: currentPathname?.startsWith(Routes.students.path()),
    },
    {
      name: "Alerts",
      route: Routes.triggers,
      icon: IconMap["trigger"],
      current: Routes.triggers.path() === currentPathname,
    },
    {
      name: "Users",
      route: Routes.users,
      icon: IconMap["users"],
      current: currentPathname?.includes(Routes.users.path()),
    },
  ] as SidebarLink[];

export const getAdminStaffingLinks = (currentPathname: string) =>
  [
    {
      name: "Job Board",
      route: Routes.job.board,
      icon: IconMap["job"],
      current: currentPathname?.startsWith("/job/"),
    },
    ...getCommonAdminSidebarLinks(currentPathname),
    {
      name: "Teachers",
      route: Routes.users,
      icon: IconMap["teacher"],
      current: currentPathname?.includes(Routes.users.path()),
    },
  ] as SidebarLink[];

export const getAdminControlPanelLinks = (currentPathname: string) =>
  [
    {
      name: "QA App",
      route: Routes.qaApp,
      icon: IconMap["qaApp"],
      current: currentPathname?.includes(Routes.qaApp.path()),
    },
    {
      name: "Zoom Client",
      route: Routes.zoomClientConfiguration,
      icon: IconMap["videoProvider"],
      current: currentPathname?.includes(Routes.zoomClientConfiguration.path()),
    },
    {
      name: "Settings",
      route: Routes.settings.usHolidays,
      icon: IconMap["settings"],
      current: currentPathname?.includes(Routes.settings.usHolidays.path()),
    },
  ] as SidebarLink[];
