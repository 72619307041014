import { gql, useQuery } from "@apollo/client";
import { TeacherJobBoardIndicatorsQuery } from "@generated/graphql";
import clsx from "clsx";
import { Spinner, Tooltip, triggerErrorToast } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { useEffect, useState } from "react";

const TEACHER_JOB_BOARD_INDICATORS = gql`
  query TeacherJobBoardIndicators($userId: ID!) {
    getTeacherJobBoardIndicators(userId: $userId) {
      newJobsCount
      jobOffersCount
    }
  }
`;

export const TeacherJobBoardIndicatorBadges = () => {
  const { user } = useAuth();
  const [newJobsCount, setNewJobsCount] = useState<number>(0);
  const [jobOffersCount, setJobOffersCount] = useState<number>(0);

  const { data, loading } = useQuery<TeacherJobBoardIndicatorsQuery>(
    TEACHER_JOB_BOARD_INDICATORS,
    {
      variables: { userId: user?.id },
      skip: !user?.id,
      onError: (error) => {
        console.error(error);
        triggerErrorToast({
          message: "Looks like something went wrong.",
          sub: "We weren't able to fetch new job count",
        });
      },
    }
  );

  useEffect(() => {
    if (data) {
      const counts = data?.getTeacherJobBoardIndicators;
      setNewJobsCount(counts.newJobsCount);
      setJobOffersCount(counts.jobOffersCount);
    }
  }, [data]);

  return (
    <div className="flex gap-x-2">
      {loading && <Spinner />}
      {!loading && jobOffersCount > 0 && (
        <Tooltip content="Your Job Offers Count">
          <div
            className={clsx(
              "flex relative justify-center items-center h-6 rounded-full text-xs",
              "text-orange-500 border-orange-500 border font-light text-center w-fit min-w-[24px]"
            )}
          >
            <p className="mx-[-4px]"> {jobOffersCount}</p>
          </div>
        </Tooltip>
      )}
      {!loading && newJobsCount > 0 && (
        <Tooltip content="New Jobs Count">
          <div
            className={clsx(
              "flex relative justify-center items-center h-6 rounded-full text-xs",
              "text-white border font-light text-center w-fit min-w-[24px]"
            )}
          >
            <p className="mx-[-4px]"> {newJobsCount}</p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
