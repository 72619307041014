import { Auth, CognitoUser } from "@aws-amplify/auth";
import { CheckIcon, ShieldCheckIcon } from "@heroicons/react/solid";
import { assertUnreachable } from "@utils/types";
import { ErrorBox, FieldError, Spinner, TbTLogo } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginButtonAndIcon } from "./components/LoginButtonAndIcon";
import { AuthStatus } from "./types";

type PasswordChangeInputs = {
  password: string;
  confirmPassword: string;
};

type Props = { cognitoUser: CognitoUser };

export function CompleteNewPasswordPage({ cognitoUser }: Props) {
  const [status, setStatus] = useState<AuthStatus>("idle");
  const auth = useAuth();

  const [changePasswordFailure, setChangePasswordFailure] = useState<
    string | null
  >(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordChangeInputs>();

  const onSubmit: SubmitHandler<PasswordChangeInputs> = async ({
    password,
    confirmPassword,
  }) => {
    setStatus("loading");
    setChangePasswordFailure(null);
    try {
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match!");
      }
      await Auth.completeNewPassword(cognitoUser, confirmPassword);
      setStatus("success");
      auth.onCompleteNewPassword();
    } catch (error: unknown) {
      console.error(error);
      setStatus("idle");
      if (error instanceof Error) {
        setChangePasswordFailure(error.message);
      } else {
        setChangePasswordFailure(
          "Password change failed. Please contact support@tutored.live"
        );
      }
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <TbTLogo width={200} />
        </div>

        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Change your password
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="mt-1">
                <input
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is required.",
                    },
                  })}
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                />
                <FieldError msg={undefined} />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <div className="mt-1">
                <input
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: "Password is required.",
                    },
                  })}
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                />

                <FieldError msg={errors.password?.message} />
              </div>
            </div>

            <ErrorBox msg={changePasswordFailure} />

            <div>
              <LoginButtonAndIcon
                Icon={getChangePasswordIcon}
                status={status}
                text="Update Password"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const getChangePasswordIcon = ({ status }: { status: AuthStatus }) => {
  switch (status) {
    case "idle":
      return <ShieldCheckIcon className="h-5" aria-hidden="true" />;
    case "success":
      return <CheckIcon className="h-5" aria-hidden="true" />;
    case "loading":
      return <Spinner />;
    default:
      return assertUnreachable(status, "Status");
  }
};
