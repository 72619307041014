import { getAccountRoleFallbackRoute } from "@utils/routes";
import { Route } from "@utils/routes/types";
import clsx from "clsx";
import { Icon, TbTLogo } from "components/shared";
import { TbTCheck, TbTCheckVariant } from "components/shared/TbTCheck";
import { TbTLogoVariant } from "components/shared/TbTLogo";
import { useAuth } from "contexts/AuthProvider";
import { useBreadcrumbs } from "contexts/BreadcrumbsProvider";
import { useState } from "react";
import { AdminMode, Breadcrumb } from "types/global";

export const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadcrumbs();
  const { setActiveRoute, isLoadingRoute } = useAuth();
  const { accountRole, isAdmin, setAdminMode } = useAuth();
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const homeRoute = getAccountRoleFallbackRoute(accountRole);

  const navigateHome = () => {
    if (isAdmin) setAdminMode(AdminMode.Dashboard);
    setActiveRoute(homeRoute);
  };

  const back =
    breadcrumbs[
      breadcrumbs.length === 1 ? breadcrumbs.length - 1 : breadcrumbs.length - 2
    ];

  const renderTriangle = (
    add: boolean,
    current: boolean,
    index: number,
    route?: Route,
    routeProps: string[] = []
  ) => (
    <div
      className={clsx(
        "absolute scale-x-[0.4] top-0 overflow-hidden group cursor-pointer h-[64px] w-[40px]",
        add ? "right-[-28px]" : "left-[-12px]"
      )}
      onClick={() => {
        if (route) setActiveRoute(route, routeProps);
      }}
      onMouseEnter={() => !current && setHoverIndex(index)}
      onMouseLeave={() => !current && hoverIndex === index && setHoverIndex(-1)}
    >
      <div
        className={clsx(
          "absolute right-[20px] rotate-45 rounded-tr h-[64px] w-[64px]",
          add ? "bg-gray-900 group" : "bg-gray-800",
          index === hoverIndex && add && "!bg-black"
        )}
      />
    </div>
  );

  const renderBreadcrumb = (
    breadcrumb: Breadcrumb,
    index: number,
    back = false
  ) => {
    const isCurrent = breadcrumb.current;

    const icon =
      breadcrumb.icon || back ? (
        <Icon
          icon={breadcrumb.icon || "chevronLeft"}
          size={5}
          color="text-gray-400"
          className="flex-shrink"
        />
      ) : null;

    return (
      <div
        key={index}
        className={clsx(
          "relative h-full w-auto max-content",
          back ? "flex sm:hidden" : "hidden sm:flex",
          !isCurrent && "group"
        )}
        style={{ zIndex: `${50 - 2 * index}` }}
        onMouseEnter={() => setHoverIndex(index)}
        onMouseLeave={() => hoverIndex === index && setHoverIndex(-1)}
      >
        {renderTriangle(false, isCurrent || true, index)}
        <div
          onClick={() => {
            (!isCurrent || isLoadingRoute) &&
              setActiveRoute(breadcrumb.route, breadcrumb.routeProps);
          }}
          className={clsx(
            "flex h-full items-center w-auto max-content break-words justify-center bg-gray-900",
            "leading-none md:leading-tight text-white text-xs font-medium cursor-pointer",
            index === hoverIndex && "!bg-black"
          )}
        >
          <div className="flex items-center justify-center text-center ml-[25px] mr-[5px]">
            {icon || breadcrumb.name}
          </div>
        </div>
        {renderTriangle(
          true,
          isCurrent || true,
          index,
          breadcrumb.route,
          breadcrumb.routeProps
        )}
      </div>
    );
  };

  const renderHomeCrumb = (
    <div
      className={clsx(
        "flex relative items-center w-[165px] z-[100] pl-3 bg-gray-900 gap-[3px] h-full cursor-pointer",
        breadcrumbs.length > 0 ? "bg-gray-900" : "bg-gray-800",
        hoverIndex === -2 && "!bg-black"
      )}
      onClick={navigateHome}
      onMouseMove={() => setHoverIndex(-2)}
      onMouseLeave={() => hoverIndex === -2 && setHoverIndex(-1)}
    >
      <TbTCheck
        size={44}
        background="bg-white"
        variant={TbTCheckVariant.BLUE_SQUARE_FILL}
      />
      <TbTLogo
        width={110}
        variant={TbTLogoVariant.WHITE_NONE}
        className="mt-[9px]"
      />
      {renderTriangle(true, false, -2, homeRoute)}
    </div>
  );

  return (
    <div className="flex items-center h-full w-fit justify-start gap-x-2">
      {renderHomeCrumb}

      {back && back.route !== homeRoute && !back?.current
        ? renderBreadcrumb(back, 0, true)
        : null}

      <div className="flex items-center h-full w-fit justify-start gap-x-[8px] ml-[0px]">
        {breadcrumbs
          .filter(({ name }) => name !== "Home")
          .map((crumb, index) => renderBreadcrumb(crumb, index))}
      </div>
    </div>
  );
};
