import clsx from "clsx";
import { Icon, SelectMenu, SelectMenuOption, Tooltip } from "components/shared";
import { IconType } from "components/shared/Icon";

export const renderBreadcrumbChild = (
  icon: IconType,
  name: string,
  options?: SelectMenuOption[],
  isCurrent?: boolean,
  crumbIndex?: number,
  setHoverIndex?: (index: number) => void,
  onSelect?: (option: SelectMenuOption) => void,
  selectedIndex?: number
) => (
  <div
    className="flex items-center h-full"
    onClick={() => {
      if (setHoverIndex && crumbIndex) setHoverIndex(crumbIndex);
    }}
  >
    <div
      className={clsx("flex sm:hidden", "h-full justify-center items-center")}
    >
      <Tooltip content={name}>
        <Icon
          icon={icon}
          size={5}
          color="text-gray-400"
          className="flex-shrink"
        />
      </Tooltip>
    </div>
    <div className={clsx("hidden sm:flex h-full justify-center items-center")}>
      {name}
    </div>

    {crumbIndex &&
      onSelect &&
      setHoverIndex &&
      options &&
      options.length > 1 && (
        <>
          <Icon
            icon={isCurrent ? "chevronDown" : "chevronRight"}
            color="text-white"
            className="mt-[1px] mr-[-11px] z-[90]"
            size={4}
          />

          <div
            className="absolute bottom-[-10px] pb-[10px] left-0 w-full h-auto"
            onMouseEnter={() => !isCurrent && setHoverIndex(crumbIndex)}
            onMouseLeave={() => !isCurrent && setHoverIndex(-1)}
          >
            <SelectMenu
              options={options}
              onSelect={(option: SelectMenuOption) => onSelect(option)}
              darkMode
              externalOpen={isCurrent}
              showListOnly
              initialIndex={selectedIndex}
              listAlignment="left"
              className="w-full"
            />
          </div>
        </>
      )}
  </div>
);
