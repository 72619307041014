import { Icon } from "components/shared";

type Props = {
  steps: boolean[];
};

export function ProgressBlips({ steps }: Props) {
  const firstFalseIndex = steps.findIndex((step) => !step);
  return (
    <div className="flex flex-row gap-x-2 items-center">
      {steps.map((step, index) => {
        if (index === firstFalseIndex) {
          return <Blip key={index} status="active" />;
        } else if (step) {
          return <Blip key={index} status="complete" />;
        } else {
          return <Blip key={index} status="incomplete" />;
        }
      })}
    </div>
  );
}

type BlipProps = {
  status: "active" | "complete" | "incomplete";
};

function Blip({ status }: BlipProps) {
  let icon: React.ReactNode = null;
  switch (status) {
    case "active":
      icon = <Icon icon="signature" color="text-blue-500" size={4} />;
      break;
    case "complete":
      icon = <Icon icon="checkmark" color="text-green-500" size={4} />;
      break;
    case "incomplete":
      icon = <Icon icon="pending" color="text-gray-400" size={3} />;
      break;
  }

  return (
    <div className="relative">
      {status === "active" && (
        <div className="absolute animate-ping h-full w-full opacity-75">
          {icon}
        </div>
      )}
      {icon}
    </div>
  );
}
