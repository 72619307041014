import clsx from "clsx";
import { Icon } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { Fragment, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { AdminMode } from "types/global";
import { useDebounce } from "use-debounce";
import { getAdminLinks } from "../Links";
import { AdminModeHeight, getAdminModeHeights } from "../utils";
import { SidebarLinks } from "./SidebarLinks";

type Props = {
  icon: React.ReactNode;
  height: AdminModeHeight;
  adminSidebarSection: AdminMode;
  setHeights: (heights: AdminModeHeight[]) => void;
};

export const AdminModeSidebarSection = ({
  icon,
  height,
  adminSidebarSection,
  setHeights,
}: Props) => {
  const { adminMode, setAdminMode, activeURL } = useAuth();
  const isActiveSection = adminSidebarSection === adminMode;
  const [updateAdminMode, setUpdateAdminMode] = useState(false);
  const [debouncedUpdateAdminMode] = useDebounce(updateAdminMode, 250);
  const isDashboardSection = adminSidebarSection === AdminMode.Dashboard;

  useEffect(() => {
    if (!debouncedUpdateAdminMode) return;
    setAdminMode(adminSidebarSection);
    setUpdateAdminMode(false);
  }, [adminSidebarSection, debouncedUpdateAdminMode, setAdminMode]);

  return (
    <Fragment key={adminSidebarSection}>
      <div
        onClick={() => {
          setUpdateAdminMode(true);
          setHeights(getAdminModeHeights(adminSidebarSection));
        }}
        className={clsx(
          "flex justify-between items-center w-full py-3 px-3",
          "border-slate-800 text-white bg-slate-700 cursor-default",
          !isActiveSection && "hover:bg-slate-900 cursor-pointer",
          isDashboardSection ? "border-b" : "border-y"
        )}
      >
        <div className="flex justify-start items-center space-x-3">
          {icon}
          <h1 className="text-base">{adminSidebarSection}</h1>
        </div>
        {isActiveSection && (
          <div className="flex w-[12px] h-[12px] justify-center items-center rounded-full bg-white mr-2">
            <Icon icon="check" size={6} color="text-blue-600" />
          </div>
        )}
      </div>

      {!isDashboardSection && (
        <div className="flex flex-col h-auto relative pl-[11px]">
          <AnimateHeight duration={250} height={height}>
            <SidebarLinks
              links={getAdminLinks(adminSidebarSection, activeURL)}
            />
          </AnimateHeight>
        </div>
      )}
    </Fragment>
  );
};
