import { Button, TbTLogo } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { IoMdExit } from "react-icons/io";

export function AuthIssuePage() {
  const auth = useAuth();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2 text-center">
      <main className="flex flex-col items-center justify-center gap-y-12">
        <TbTLogo />
        <h1 className="text-4xl font-medium">Well, that&apos;s not right...</h1>
        <h2 className="text-xl font-light">
          We apologize, but due to an issue you must sign out and then back in
          before continuing.
        </h2>
        <div className="flex flex-col items-center justify-center gap-y-8">
          <Button onClick={() => auth.signOut()}>
            <span className="flex space-x-2 items-center text-2xl">
              <span>Sign Out</span>
              <IoMdExit className="w-8 h-8" />
            </span>
          </Button>
          <p className="italic font-light">
            We promise, it&apos;s not you &ndash; it&apos;s us.
          </p>
        </div>
      </main>
    </div>
  );
}
