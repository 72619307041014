import { gql, useQuery } from "@apollo/client";
import {
  AgreementModalGetPendingAgreementsQuery,
  AgreementModalGetPendingAgreementsQueryVariables,
} from "@generated/graphql";
import { ErrorBox, LoadingSkeleton, Modal } from "components/shared";
import { noop } from "lodash";
import pluralize from "pluralize";
import { AgreementModalBody } from "./components/AgreementModalBody";

const GET_PENDING_AGREEMENTS = gql`
  query AgreementModalGetPendingAgreements {
    getPendingAgreements {
      ...AgreementModalBody_AgreementDocument
    }
  }
  ${AgreementModalBody.fragments.agreementDocument}
`;

type Props = {
  onClose(): void;
};

export function AgreementModal({ onClose }: Props) {
  const { data, loading, error } = useQuery<
    AgreementModalGetPendingAgreementsQuery,
    AgreementModalGetPendingAgreementsQueryVariables
  >(GET_PENDING_AGREEMENTS, {
    fetchPolicy: "network-only",
  });

  return (
    <Modal
      show
      onClose={noop}
      icon={<Modal.Icon icon="signature" />}
      title={`Please review the following ${pluralize(
        "agreement",
        data?.getPendingAgreements?.length ?? 1
      )}...`}
      width="xxlarge"
      dataTest="agreement-modal"
    >
      {loading ? (
        <LoadingSkeleton />
      ) : error ? (
        <ErrorBox msg={error.message} />
      ) : (
        <AgreementModalBody
          agreementDocuments={data?.getPendingAgreements ?? []}
          onClose={onClose}
        />
      )}
    </Modal>
  );
}
