import {
  AgreementDocumentPromptType,
  AgreementPrompts_AgreementDocumentPromptFragment,
} from "@generated/graphql";
import gql from "graphql-tag";
import { ResponseState } from "../types";
import { AgreementCheckbox } from "./AgreementCheckbox";
import { AgreementSignature } from "./AgreementSignature";

AgreementPrompts.fragments = {
  agreementDocumentPrompt: gql`
    fragment AgreementPrompts_AgreementDocumentPrompt on AgreementDocumentPrompt {
      index
      type
      text
      required
    }
  `,
};

type Props = {
  agreementDocumentPrompts: AgreementPrompts_AgreementDocumentPromptFragment[];
  responseState: ResponseState[];
  userFullName: string;
  onResponseStateChange: (index: number, value: string) => void;
};

export function AgreementPrompts({
  agreementDocumentPrompts,
  responseState,
  userFullName,
  onResponseStateChange,
}: Props) {
  return (
    <div className="flex flex-col gap-y-2">
      {agreementDocumentPrompts.map((prompt) => {
        const response = responseState[prompt.index];
        if (!response) return null;

        return (
          <div key={prompt.index} className="flex flex-col gap-y-2">
            {prompt.type === AgreementDocumentPromptType.Checkbox ? (
              <AgreementCheckbox
                text={prompt.text ?? "Yes?"}
                value={response.value}
                required={prompt.required}
                error={response.error}
                onChange={(value) => onResponseStateChange(prompt.index, value)}
              />
            ) : (
              <AgreementSignature
                userFullName={userFullName}
                value={response.value}
                required={prompt.required}
                error={response.error}
                setValue={(value) => onResponseStateChange(prompt.index, value)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
