import { gql, useMutation, useQuery } from "@apollo/client";
import {
  DismissNotificationMutation,
  MissingHostFoundSubscription,
  MissingHostNotificationsQuery,
} from "@generated/graphql";
import { Menu, Transition } from "@headlessui/react";
import { Icon } from "components/shared/Icon";
import { useAuth } from "contexts/AuthProvider";
import { uniqBy } from "lodash";
import { Fragment, useEffect } from "react";
import { NotificationCell } from "./components/NotificationCell";

const MISSING_HOST_NOTIFICATIONS_QUERY = gql`
  query MissingHostNotifications($userId: ID!) {
    missingHostNotifications(userId: $userId) {
      ...NotificationCell_MissingHostNotification
    }
  }
  ${NotificationCell.fragments.notification}
`;

const DISMISS_NOTIFICATION_MUTATION = gql`
  mutation DismissNotification($userId: ID!, $meetingId: String!) {
    dismissNotification(userId: $userId, meetingId: $meetingId)
  }
`;

const MISSING_HOST_SUBSCRIPTION = gql`
  subscription MissingHostFound($userId: ID!) {
    missingHostFound(userId: $userId) {
      ...NotificationCell_MissingHostNotification
    }
  }
  ${NotificationCell.fragments.notification}
`;

const NOTIFICATION_SOUND_URL = `https://ia800203.us.archive.org/14/items/slack_sfx/been_tree.mp3`;
const NOTIFICATION_VOLUME = 0.2;

export function NotificationDropdown() {
  const auth = useAuth();

  const { data, refetch, subscribeToMore, loading } =
    useQuery<MissingHostNotificationsQuery>(MISSING_HOST_NOTIFICATIONS_QUERY, {
      variables: {
        userId: auth?.user?.id,
      },
      fetchPolicy: "cache-first",
      skip: !Boolean(auth?.user?.id),
    });

  const [dismissNotification] = useMutation<DismissNotificationMutation>(
    DISMISS_NOTIFICATION_MUTATION,
    { onCompleted: refetch }
  );

  const onDismiss = (userId: string, meetingId: string) =>
    dismissNotification({ variables: { userId, meetingId } });

  useEffect(() => {
    const subscribeToNotifications = () => {
      subscribeToMore({
        document: MISSING_HOST_SUBSCRIPTION,
        variables: { userId: auth?.user?.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newNotification = (
            subscriptionData.data as unknown as MissingHostFoundSubscription
          ).missingHostFound;

          const previousNotifications = Array.isArray(
            prev.missingHostNotifications
          )
            ? prev.missingHostNotifications
            : [];

          if (newNotification.dismissed) {
            return Object.assign({}, prev, {
              missingHostNotifications: [
                ...previousNotifications.filter(
                  (notification) =>
                    notification.meetingId !== newNotification.meetingId
                ),
              ],
            });
          }

          const notificationSound = new Audio(NOTIFICATION_SOUND_URL);
          notificationSound.volume = NOTIFICATION_VOLUME;
          notificationSound.play();
          return Object.assign({}, prev, {
            missingHostNotifications: uniqBy(
              [newNotification, ...previousNotifications],
              "meetingId"
            ),
          });
        },
      });
    };

    if (!loading) subscribeToNotifications();
  }, [auth, loading, subscribeToMore]);

  if (
    !data?.missingHostNotifications ||
    !data?.missingHostNotifications?.length
  ) {
    return (
      <div className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold">
        <Icon icon="notification" size={6} color="text-gray-500" />
      </div>
    );
  }

  return (
    <div className="rounded-full hover:text-gray-500">
      <span className="sr-only">View notifications</span>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold cursor-pointer">
          <div className="flex justify-center items-center absolute top-[1px] text-xs right-[2px] w-[23px] h-[23px] bg-rose-700/90 text-white rounded-full">
            {data?.missingHostNotifications?.length}
          </div>
          <Icon icon="notification" size={6} color="text-white" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="absolute right-0 z-10 mt-2 w-[550px] rounded-md overflow-hidden shadow-lg"
          >
            <div className="divide-y divide-gray-200 even:bg-white/[0.96] odd:bg-slate-100/[0.96]">
              {data.missingHostNotifications.map((notification) => (
                <NotificationCell
                  onDismiss={onDismiss}
                  notification={notification}
                  key={notification.id}
                />
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
