import clsx from "clsx";
import { useState } from "react";
import { Icon } from "../Icon";

type Props = {
  text: string;
  showIcon?: boolean;
  className?: string;
  textClassName?: string;
};

export const CopyText = ({
  text,
  className,
  showIcon,
  textClassName,
}: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 400);
  };

  return (
    <div
      className={clsx(
        "flex w-full h-full justify-start items-center group cursor-pointer",
        className
      )}
      onClick={handleCopy}
    >
      <div className={textClassName}>{text}</div>
      <div
        className={clsx(
          "relative pl-1 my-[-4px]",
          showIcon ? "flex" : "hidden group-hover:flex "
        )}
      >
        <Icon icon="copy" size={4} color="text-slate-600" />
        <div
          className={clsx(
            "text-slate-400 text-xs",
            "absolute transition left-[-10px] w-fit h-fit ease-in-out",
            copied ? "opacity-1 top-[-17px]" : "top-[-30px] opacity-0"
          )}
        >
          Copied
        </div>
      </div>
    </div>
  );
};
