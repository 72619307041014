import { Routes, arePathsEqual } from "@utils/routes";

// Until we update the fact that Engagements routes rely on orgIds unlike cohort routes
export const getEngagementCurrentRoute = (currentPathname: string): boolean => {
  if (arePathsEqual(Routes.engagements.path(), currentPathname)) return true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const node: any = Routes.engagement as any;
  for (const key in node) {
    const route = node[key];
    if ("path" in route && arePathsEqual(route.path(), currentPathname))
      return true;
  }
  return false;
};

export const getOrganizationCurrentRoute = (currentPathname: string): boolean =>
  currentPathname?.includes(Routes.organizations.path()) &&
  !getEngagementCurrentRoute(currentPathname);
