import { Container, TbTLogo } from "components/shared";

type Props = { headerText: string; children: React.ReactNode };

export const CenteredLogoContainer = ({ headerText, children }: Props) => {
  const renderHeaderAndLogo = (text: string) => (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="flex justify-center">
        <TbTLogo width={120} />
      </div>
      <h2 className="mt-4 text-center text-3xl font-extrabold text-gray-900">
        {text}
      </h2>
    </div>
  );

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {renderHeaderAndLogo(headerText)}
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Container flex>{children}</Container>
      </div>
    </div>
  );
};
