import { gql } from "@apollo/client";
import { AgreementModalBody_AgreementDocumentFragment } from "@generated/graphql";
import { Modal } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import pluralize from "pluralize";
import { useState } from "react";
import { AgreementDocument } from "./components/AgreementDocument";
import { ProgressBlips } from "./components/ProgressBlips";

AgreementModalBody.fragments = {
  agreementDocument: gql`
    fragment AgreementModalBody_AgreementDocument on AgreementDocument {
      title
      ...AgreementDocument_AgreementDocument
    }
    ${AgreementDocument.fragments.agreementDocument}
  `,
};

type Props = {
  agreementDocuments: AgreementModalBody_AgreementDocumentFragment[];
  onClose(): void;
};

export function AgreementModalBody({ agreementDocuments, onClose }: Props) {
  const { signOut } = useAuth();

  const [agreementCompletionState, setAgreementCompletionState] = useState(
    agreementDocuments.map((_) => false)
  );

  const activeIndex = agreementCompletionState.findIndex((s) => !s);
  const isComplete = activeIndex === -1;

  const markCompleted = (index: number) => {
    setAgreementCompletionState((prev) =>
      prev.map((s, i) => (i === index ? true : s))
    );
  };

  const agreementDocument = agreementDocuments[activeIndex];
  if (!isComplete && !agreementDocument) return null;

  return (
    <div className="flex flex-col gap-y-4">
      {isComplete ? (
        <div className="flex flex-col gap-y-1 text-center py-5">
          <h2 className="text-lg text-gray-800 font-medium">
            Thank you for reviewing and signing the{" "}
            {pluralize("agreement", agreementDocuments.length)}!
          </h2>
          <p>You can now close this dialog.</p>
          <p className="italic">
            (Your browser will refresh itself automatically)
          </p>
        </div>
      ) : (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col">
            <h1 className="text-lg text-gray-800 font-medium text-center">
              {agreementDocument.title}{" "}
              <span className="italic">
                (rev. {agreementDocument.revision})
              </span>
            </h1>
            {agreementDocuments.length > 1 && (
              <h2 className="text-md text-gray-800 font-base italic text-center">
                Agreement {activeIndex + 1}/{agreementDocuments.length}
              </h2>
            )}
          </div>
          <AgreementDocument
            key={activeIndex}
            agreementDocument={agreementDocument}
            markCompleted={() => markCompleted(activeIndex)}
          />
        </div>
      )}

      <div className="flex flex-row justify-between items-end">
        <ProgressBlips steps={agreementCompletionState} />
        <Modal.Buttons>
          {isComplete ? (
            <Modal.Button type="confirm" onClick={onClose}>
              Close
            </Modal.Button>
          ) : (
            <Modal.Button type="delete" onClick={signOut}>
              Sign Out
            </Modal.Button>
          )}
        </Modal.Buttons>
      </div>
    </div>
  );
}
