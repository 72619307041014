import { gql, useQuery } from "@apollo/client";
import {
  GetPdCourseTableCountQuery,
  GetPdCourseTableCountQueryVariables,
} from "@generated/graphql";
import clsx from "clsx";
import { Spinner, triggerErrorToast } from "components/shared";
import { useAuth } from "contexts/AuthProvider";

const GET_PD_COURSE_COUNT = gql`
  query GetPDCourseTableCount($userId: ID!) {
    getPDCourseTable(userId: $userId) {
      id
      courseCount
      hasOverdue
    }
  }
`;

type Props = { className: string };

export const PDCoursesNotificationBadge = ({ className }: Props) => {
  const { user } = useAuth();

  const { data, loading } = useQuery<
    GetPdCourseTableCountQuery,
    GetPdCourseTableCountQueryVariables
  >(GET_PD_COURSE_COUNT, {
    variables: { userId: user?.id as string },
    skip: !user?.id,
    onError: (error) => {
      console.error(error);
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: "We weren't able to fetch PD course count",
      });
    },
  });

  return (
    <>
      {loading && <Spinner />}
      {data?.getPDCourseTable.courseCount && (
        <div
          className={clsx(
            "flex justify-center items-center rounded-full text-white border font-light text-center text-xs",
            data?.getPDCourseTable.hasOverdue && "bg-red-500",
            className
          )}
        >
          {data?.getPDCourseTable.courseCount}
        </div>
      )}
    </>
  );
};
