import { gql } from "@apollo/client";
import { VisitorBreadcrumbs_VisitorFragment } from "@generated/graphql";
import { Routes } from "@utils/routes";
import clsx from "clsx";
import { TbTLogo } from "components/shared";
import { TbTCheck, TbTCheckVariant } from "components/shared/TbTCheck";
import { TbTLogoVariant } from "components/shared/TbTLogo";
import { useAuth } from "contexts/AuthProvider";
import { useBreadcrumbs } from "contexts/BreadcrumbsProvider";
import { addDays } from "date-fns";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { renderBreadcrumbChild } from "./helpers";
import { VisitorEngagementOption, VisitorRecordOption } from "./types";
import {
  getEngagementSelectOptions,
  getRecordIndex,
  getRecordSelectOptions,
} from "./utils";

const ORG_INDEX = 0;
const ENG_INDEX = 1;
const REP_INDEX = 2;

VisitorBreadcrumbs.fragments = {
  visitor: gql`
    fragment VisitorBreadcrumbs_Visitor on OrganizationVisitor {
      id
      engagements {
        id
        name
        endDate
        startDate
        cohortSubjects
        limitedAccessKey
        enableStudentGrading
      }
      organization {
        id
        name
      }
    }
  `,
};

type Props = {
  breadcrumbData?: VisitorBreadcrumbs_VisitorFragment;
  visitorAccessKey?: string;
};

export function VisitorBreadcrumbs({
  visitorAccessKey,
  breadcrumbData,
}: Props) {
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const { setActiveRoute, activeRoute, activeURL } = useAuth();
  const { breadcrumbs, setVisitorDetails, visitorDetails } = useBreadcrumbs();

  const { engagements, organization } = breadcrumbData ?? {};
  const { engagementName, organizationName } = visitorDetails;
  const reportName = breadcrumbs[breadcrumbs.length - 1]?.name ?? "Report";

  const { engagementOptions, selectedEngagementIndex, currEngagement } =
    useMemo(() => {
      const engArray = engagements ?? [];
      const engagementOptions = getEngagementSelectOptions(engArray);
      const selectedEngagementIndex = engArray.findIndex(
        ({ limitedAccessKey }) => limitedAccessKey === visitorAccessKey
      );
      return {
        engagementOptions,
        selectedEngagementIndex,
        currEngagement: engArray[selectedEngagementIndex ?? 0] ?? undefined,
      };
    }, [engagements, visitorAccessKey]);

  const { recordOptions, selectedRecordIndex } = useMemo(() => {
    const recordOptions = getRecordSelectOptions(currEngagement);
    const selectedRecordIndex = getRecordIndex(
      activeRoute,
      activeURL,
      recordOptions
    );
    return { recordOptions, selectedRecordIndex };
  }, [activeRoute, activeURL, currEngagement]);

  useEffect(() => {
    if (breadcrumbData) {
      const newVisitorDetails = {
        organizationName: organization?.name ?? "Organization",
        engagementName: currEngagement?.name ?? "Engagement",
        engagementStart: currEngagement?.startDate ?? new Date(),
        engagementEnd: currEngagement?.endDate ?? addDays(new Date(), 7),
      };
      if (!isEqual(newVisitorDetails, visitorDetails))
        setVisitorDetails(newVisitorDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbData, currEngagement, organization, visitorDetails]);

  const renderTriangle = (add: boolean, current: boolean, index?: number) => (
    <div
      className={clsx(
        "absolute scale-x-[0.4] top-0 overflow-hidden group cursor-pointer h-[64px] w-[40px]",
        add ? "right-[-28px]" : "left-[-12px]"
      )}
      onMouseEnter={() => !current && index && setHoverIndex(index)}
      onMouseLeave={() => !current && hoverIndex === index && setHoverIndex(-1)}
    >
      <div
        className={clsx(
          "absolute right-[20px] rotate-45 rounded-tr h-[64px] w-[64px]",
          add ? "bg-gray-900 group" : "bg-gray-800",
          index === hoverIndex && add && "!bg-black"
        )}
      />
    </div>
  );

  const renderVisitorCrumb = (
    index: number,
    child: React.ReactNode,
    showHover = true
  ) => (
    <div
      key={index}
      className={clsx(
        "relative h-full w-auto max-content",
        index !== hoverIndex && "group"
      )}
      style={{ zIndex: `${50 - 2 * index}` }}
      onMouseEnter={() => showHover && setHoverIndex(index)}
      onMouseLeave={() =>
        showHover && hoverIndex === index && setHoverIndex(-1)
      }
    >
      {renderTriangle(false, index === hoverIndex || true, index)}
      <div
        className={clsx(
          "flex h-full items-center w-auto max-content break-words justify-center bg-gray-900",
          "leading-none md:leading-tight text-white text-xs font-medium cursor-pointer",
          index === hoverIndex && "!bg-black"
        )}
      >
        <div className="flex items-center justify-center h-full text-center ml-[25px] mr-[5px]">
          {child}
        </div>
      </div>
      {renderTriangle(true, index === hoverIndex || true, index)}
    </div>
  );

  const renderHomeCrumb = (
    <div
      className={clsx(
        "flex relative items-center w-[165px] z-[100] pl-3 gap-[3px] h-full cursor-pointer bg-gray-900"
      )}
    >
      <TbTCheck
        size={44}
        background="bg-white"
        variant={TbTCheckVariant.BLUE_SQUARE_FILL}
      />
      <TbTLogo
        width={110}
        variant={TbTLogoVariant.WHITE_NONE}
        className="mt-[9px]"
      />
      {renderTriangle(true, false)}
    </div>
  );

  const renderEngagementCrumb = () => {
    const onSelect = (option: VisitorEngagementOption) => {
      if (option.limitedAccessKey) {
        if (activeRoute === Routes.visitorRoster) {
          setActiveRoute(activeRoute, [option.limitedAccessKey as string]);
        } else {
          setActiveRoute(Routes.engagement.publicStudentAttendancePage, [
            option.limitedAccessKey as string,
          ]);
        }
      }
    };

    return renderVisitorCrumb(
      ENG_INDEX,
      currEngagement
        ? renderBreadcrumbChild(
            "engagement",
            engagementName ?? "Engagement",
            engagementOptions,
            hoverIndex === ENG_INDEX,
            ENG_INDEX,
            setHoverIndex,
            onSelect,
            selectedEngagementIndex
          )
        : undefined,
      engagementOptions.length > 1
    );
  };

  const renderReportCrumb = () => {
    const onSelect = (option: VisitorRecordOption) => {
      if (option.route) {
        setActiveRoute(option.route, option.routeProps);
      }
    };

    return renderVisitorCrumb(
      REP_INDEX,
      renderBreadcrumbChild(
        "report",
        reportName,
        recordOptions,
        hoverIndex === REP_INDEX,
        REP_INDEX,
        setHoverIndex,
        onSelect,
        selectedRecordIndex
      ),
      recordOptions.length > 1
    );
  };

  return (
    <div className="flex items-center h-full w-fit justify-start gap-x-2">
      {renderHomeCrumb}
      {renderVisitorCrumb(
        ORG_INDEX,
        renderBreadcrumbChild(
          "organization",
          organizationName ?? "Organization"
        ),
        false
      )}
      {renderEngagementCrumb()}
      {renderReportCrumb()}
    </div>
  );
}
