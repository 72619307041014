import clsx from "clsx";
import { Link, Spinner } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { AdminMode } from "types/global";
import { SidebarLink } from "../types";

type Props = { links: SidebarLink[] };

export const SidebarLinks = ({ links }: Props) => {
  const { Managing } = AdminMode;
  const { isAdmin, isDashboardMode, isLoadingRoute, setAdminMode } = useAuth();

  const renderAdminSubheadMenuBranches = (active: boolean, index: number) => (
    <div className="flex w-4 h-full border-gray-400">
      {index === 0 && (
        <div className="absolute left-0 w-1 h-[6px] bottom-full border-l" />
      )}
      <div className="absolute w-[14px] border-l border-b top-0 h-1/2" />
      {active && (
        <div className="absolute flex z-[2] h-10 top-[5px] left-[9px] w-[10px] bg-blue-600 rounded-l-md" />
      )}
      {index !== links.length - 1 && (
        <div className="absolute w-1 border-l bottom-0 h-1/2" />
      )}
    </div>
  );

  const renderLink = (link: SidebarLink, index: number) => (
    <div
      key={link.name}
      className={clsx(
        "flex relative w-full justify-start items-center h-[50px]",
        isAdmin && index === 0 && "mt-1"
      )}
    >
      {isAdmin && renderAdminSubheadMenuBranches(link.current, index)}
      <Link
        route={link.route}
        push={!isDashboardMode}
        routeProps={link.routeProps}
        onClick={() =>
          isDashboardMode && setAdminMode(Managing, false, link.route)
        }
        className={clsx(
          link.current
            ? "bg-slate-700 text-white"
            : "bg-slate-900/20 border border-gray-900/10 text-slate-100 hover:bg-slate-600 hover:border-sate-600 group",
          "flex w-full font-medium text-sm h-10 rounded-md",
          "align-middle items-center justify-between pr-3 hover:!no-underline"
        )}
      >
        <div
          className={clsx(
            "flex items-center w-full h-full px-3 py-2 ",
            !link.current &&
              "transform transition-transform duration-[120ms] ease-in-out hover:translate-x-[2px]"
          )}
        >
          <link.icon
            className={clsx(
              "mr-3 flex-shrink-0 h-5 w-5",
              link.current ? "text-white" : "text-gray-100"
            )}
            aria-hidden="true"
          />
          <div className="text-center">{link.name}</div>
        </div>
        {link.current && isLoadingRoute ? (
          <Spinner color="text-gray-400" />
        ) : (
          link.counter && <link.counter className="px-2 py-[1.5px]" />
        )}
      </Link>
    </div>
  );

  return (
    <nav className="flex flex-col pl-2 pr-3 pb-2">
      {links.map((link, index) => renderLink(link, index))}
    </nav>
  );
};
