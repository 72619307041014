import { AdminMode } from "types/global";

export type AdminModeHeight = "auto" | number;

export const getAdminModeIndex = (mode: AdminMode): number =>
  Object.values(AdminMode).indexOf(mode);

export const getAdminModeHeights = (
  adminMode: AdminMode
): AdminModeHeight[] => {
  switch (adminMode) {
    case AdminMode.Dashboard:
    case AdminMode.Managing:
      return [0, "auto", 0, 0];
    case AdminMode.Staffing:
      return [0, 0, "auto", 0];
    case AdminMode.ControlPanel:
      return [0, 0, 0, "auto"];
  }
};
