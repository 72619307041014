import { Checkbox, FieldError } from "components/shared";
import { CHECKBOX_FALSE, CHECKBOX_TRUE } from "../constants";

type Props = {
  text: string;
  value: string;
  required: boolean;
  error: string | null;
  onChange: (value: string) => void;
};

export function AgreementCheckbox({
  text,
  value,
  required,
  error,
  onChange,
}: Props) {
  return (
    <div className="flex flex-col gap-y-1">
      <Checkbox
        label={`${text}${required ? "" : " (optional)"}`}
        checked={value === CHECKBOX_TRUE}
        onChange={() =>
          onChange(value === CHECKBOX_TRUE ? CHECKBOX_FALSE : CHECKBOX_TRUE)
        }
        labelSide="right"
        inputClassName="w-6 h-6"
        labelClassName="text-lg font-medium text-gray-700 cursor-pointer"
      />
      {error && <FieldError msg={error} />}
    </div>
  );
}
